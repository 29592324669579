const MHeaderLine = () => {
  return (
    <div className="fixed left-0 top-0 w-full h-8 pb-0.5 bg-secondary text-white z-30">
      <div className="relative w-full h-8 px-[calc((100%-var(--container))/2)] flex items-center">
        <div className="relative w-fit min-w-[480px] h-8 hidden lg:flex items-center">
          <span className="text-point">
            서울&nbsp;·&nbsp;경기&nbsp;·&nbsp;인천&nbsp;전지역&nbsp;No.1&nbsp;유품정리&nbsp;특수청소&nbsp;전문업체
          </span>
          <p className="leading-[0.2]">&nbsp;|&nbsp;</p>
        </div>
        <div className="relative w-fit h-fit flow-text">
          <ul className="flex gap-5 flow-wrap text-base">
            <li>김00 (고독사정리)</li>
            <li>이OO (유품정리)</li>
            <li>오OO (쓰레기집청소)</li>
            <li>신OO (특수청소)</li>
            <li>임OO (빈집정리)</li>
            <li>김OO (폐기물처리)</li>
            <li>이OO (고독사정리)</li>
            <li>오OO (쓰레기집청소)</li>
            <li>신OO (폐기물처리)</li>
            <li>임OO (특수청소)</li>
          </ul>
          <ul className="flex gap-5 flow-wrap text-base">
            <li>김00 (고독사정리)</li>
            <li>이OO (유품정리)</li>
            <li>오OO (쓰레기집청소)</li>
            <li>신OO (특수청소)</li>
            <li>임OO (빈집정리)</li>
            <li>김OO (폐기물처리)</li>
            <li>이OO (고독사정리)</li>
            <li>오OO (쓰레기집청소)</li>
            <li>신OO (폐기물처리)</li>
            <li>임OO (특수청소)</li>
          </ul>
        </div>
        <div className="relative w-fit min-w-36 lg:min-w-[180px] h-8 flex items-center">
          <p className="leading-[0.2]">&nbsp;|&nbsp;&nbsp;</p>
          <span className="text-point xl:text-xl leading-none">
            실시간&nbsp;견적현황
          </span>
        </div>
      </div>
    </div>
  );
};

export default MHeaderLine;
