const Service = () => {
  const serviceList = [
    {
      img: "/images/service1.png",
      txt: "유품정리 서비스",
      link: "https://blog.naver.com/PostList.naver?blogId=langlangshop&from=postList&categoryNo=32",
    },
    {
      img: "/images/service2.png",
      txt: "빈집정리 서비스",
      link: "https://blog.naver.com/PostList.naver?blogId=langlangshop&from=postList&categoryNo=33",
    },
    {
      img: "/images/service3.png",
      txt: "폐업정리 서비스",
      link: "https://blog.naver.com/PostList.naver?blogId=langlangshop&from=postList&categoryNo=34",
    },
    {
      img: "/images/service4.png",
      txt: "쓰레기집 서비스",
      link: "https://blog.naver.com/PostList.naver?blogId=langlangshop&from=postList&categoryNo=35",
    },
    {
      img: "/images/service5.png",
      txt: "고독사현장 서비스",
      link: "https://blog.naver.com/PostList.naver?blogId=langlangshop&from=postList&categoryNo=36",
    },
    {
      img: "/images/service6.png",
      txt: "특수청소 서비스",
      link: "https://blog.naver.com/PostList.naver?blogId=langlangshop&from=postList&categoryNo=37",
    },
    {
      img: "/images/service7.png",
      txt: "폐기물처리 서비스",
      link: "https://blog.naver.com/PostList.naver?blogId=langlangshop&from=postList&categoryNo=38",
    },
    {
      img: "/images/service8.png",
      txt: "이사정리 서비스",
      link: "https://blog.naver.com/PostList.naver?blogId=langlangshop&from=postList&categoryNo=39",
    },
  ];
  return (
    <div
      id="service"
      className="relative w-full h-full px-[calc((100%-var(--container))/2)] mx-auto py-14 lg:pb-28 xl:pb-48"
    >
      <div
        id="title"
        className="relative w-full h-fit text-center mb-5 lg:mb-10"
      >
        <div className="relative w-full h-fit flex justify-center items-end mb-3 lg:mb-5 flex-wrap sm:flex-nowrap">
          <div className="relative w-48 md:w-56 h-fit md:mx-0 mb-2 xs:mb-0">
            <img
              className="!relative w-full h-auto object-contain"
              alt="logo"
              src="/images/logo.png"
              width={430}
              height={104}
            />
          </div>
          <h2 className="leading-none font-light">
            <span className="hidden sm:inline-block">의</span> 서비스&nbsp;목록
          </h2>
        </div>
        <p className=" lg:text-xl">
          BEFORE&nbsp;&&nbsp;AFTER가 확실한&nbsp;집치울때의&nbsp;작업현장!
          지금&nbsp;바로&nbsp;확인해보세요
        </p>
      </div>
      <div className="relative w-full h-fit grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 lg:gap-5">
        {serviceList.map((v, i) => (
          <div
            key={i}
            data-aos="fade-up"
            onClick={() => window.open(v.link)}
            className="relative w-full h-full bg-white p-3 border shadow-lg rounded-md cursor-pointer"
          >
            <div className="relative w-full h-full overflow-hidden">
              <img
                className="!relative w-full h-44 md:h-52 lg:h-64 xl:h-72 object-cover hover:scale-110 transition-transform duration-300"
                alt="service"
                src={v.img}
                width={328}
                height={355}
              />
            </div>
            <div className="absolute bottom-0 left-0 w-full h-fit bg-white text-center text-light pt-3 pb-2">
              <p className="lg:text-xl xl:text-2xl text-nowrap">{v.txt}</p>
            </div>
          </div>
        ))}
      </div>
      <div
        // data-aos="fade-up"
        className="relative w-full h-full mt-5 lg:absolute lg:left-0 xl:left-3 lg:bottom-0 lg:w-fit lg:h-fit "
      >
        <img
          onClick={() => window.open("https://blog.naver.com/langlangshop")}
          className="hidden lg:block !relative w-auto h-full max-h-72 2xl:max-h-96 mx-auto object-contain cursor-pointer"
          src="/images/service9.png"
          alt="icon"
          width={407}
          height={615}
        />
        <img
          onClick={() => window.open("https://blog.naver.com/langlangshop")}
          className="block lg:hidden !relative w-auto h-full max-h-72 2xl:max-h-96 mx-auto object-contain"
          src="/images/service10.png"
          alt="icon"
          width={407}
          height={615}
        />
      </div>
    </div>
  );
};

export default Service;
