import axios from "axios";
import { useState } from "react";
import Swal from "sweetalert2";

const QuickBar = () => {
  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [address, setAddress] = useState<string>("");

  const [loading, setLoading] = useState(false);

  const handlerPostContact = async () => {
    if (!name || !phone || !address) {
      Swal.fire({
        title: "이름, 연락처, 주소를 모두 입력해주세요.",
        icon: "error",
      });
      return false;
    }

    if (phone.length !== 11 || !phone.includes("010")) {
      alert("010을 포함한 연락처를 모두 입력해주세요.");
      return false;
    }

    if (!window.confirm("문의 접수를 위한 개인정보 수집에 동의하시나요?")) {
      return false;
    }

    setLoading(true);

    await axios
      .post("/api/contact/quick", {
        name: name,
        phone: phone,
        address: address,
        path: sessionStorage.getItem("path"),
      })
      .then((res) => {
        if (res.data.result === "0000") {
          Swal.fire({
            title: "문의 완료!",
            text: "문의 등록이 완료되었습니다.",
            icon: "success",
          }).then(() => (window.location.href = "/transform"));
        }
      })
      .catch((e) => {
        setLoading(false);
        Swal.fire({
          title: e,
          icon: "error",
        }).then(() => (window.location.href = "/"));
      });
  };
  return (
    <div className="hidden lg:block fixed left-0 bottom-10 w-full h-fit px-[calc((100%-var(--container))/5)] z-40">
      <div className=" relative w-fit h-fit flex justify-center items-center gap-5 px-8 py-1.5 bg-black border border-point rounded-full">
        <div className=" relative w-fit h-full pr-5 border-r border-white">
          <img src="/images/whiteLogo.png" alt="logo" width={300} height={73} />
        </div>
        <div
          onClick={() => (window.location.href = "tel:010-9060-7799")}
          className=" relative w-fit h-full"
        >
          <img
            src="/images/quickNum.png"
            alt="number"
            width={542}
            height={49}
          />
        </div>
        <div className=" relative w-fit h-full grid grid-cols-4 gap-2">
          <div className=" relative w-full h-full">
            <label
              htmlFor="name1"
              className=" absolute left-1 z-10 top-2 lg:text-xl"
            >
              이름
            </label>
            <input
              type="text"
              value={name}
              className="!pl-12 !text-xs"
              id="name1"
              placeholder="이름을 입력해주세요."
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className=" relative w-full h-full">
            <label
              htmlFor="phone1"
              className=" absolute left-1 z-10 top-2 lg:text-xl"
            >
              연락처
            </label>
            <input
              className="!pl-16 !text-xs"
              type="text"
              id="phone1"
              value={phone}
              maxLength={11}
              placeholder="연락처를 입력해주세요."
              onChange={(e) =>
                setPhone(
                  e.target.value
                    .replace(/[^0-9.]/g, "")
                    .replace(/(\..*)\./g, "$1")
                )
              }
            />
          </div>
          <div className="col-span-2 relative w-full h-full">
            <label
              htmlFor="address1"
              className=" absolute left-1 z-10 top-2 lg:text-xl"
            >
              주소&nbsp;
            </label>
            <input
              className="!pl-12 !text-xs"
              type="text"
              value={address}
              id="address1"
              placeholder="주소를 입력해주세요."
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
        </div>
        <button
          type="button"
          disabled={loading}
          onClick={handlerPostContact}
          className="text-point"
        >
          <h2 className="manru">전송</h2>
        </button>
      </div>
    </div>
  );
};

export default QuickBar;
