const About = () => {
  const aboutList = [
    { img: "/images/about1.png", txt1: "출장비 없는", txt2: "출장방문 견적!" },
    {
      img: "/images/about2.png",
      txt1: "이해할 수 있는",
      txt2: "합리적인 견적!",
    },
    {
      img: "/images/about3.png",
      txt1: "서울·경기·인천",
      txt2: "전지역 해결가능!",
    },
    {
      img: "/images/about4.png",
      txt1: "신뢰할 수 있는",
      txt2: "BEFORE & AFTER",
    },
  ];
  return (
    <div
      id="about"
      className="relative w-full h-full px-[calc((100%-var(--container))/2)]  mx-auto py-14 lg:py-28 xl:pb-36"
    >
      <div
        id="title"
        className="relative w-full h-fit text-center mb-5 lg:mb-10"
      >
        <div className="relative w-full h-fit flex justify-center items-end mb-3 lg:mb-5 flex-wrap sm:flex-nowrap">
          <div className="relative w-48 md:w-56 h-fit md:mx-0 mb-2 xs:mb-0">
            <img
              className="!relative w-full h-auto object-contain"
              alt="logo"
              src="/images/logo.png"
              width={430}
              height={104}
            />
          </div>
          <h2 className="leading-none font-light">
            <span className="hidden sm:inline-block">의</span> 약속&nbsp;4가지
          </h2>
        </div>
        <p className=" lg:text-xl">
          고객님께서 믿고 맡겨주신 만큼 최선을 다하는
          집치울때가&nbsp;되겠습니다!
        </p>
      </div>
      <div className="relative w-full h-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 lg:gap-8 ">
        {aboutList.map((v, i) => (
          <div
            key={i}
            data-aos="fade-up"
            className="relative w-full h-full overflow-hidden"
          >
            <img
              className="!relative w-full h-32 sm:h-40 lg:h-96 object-cover hover:scale-110 transition-transform duration-300"
              alt="img"
              src={v.img}
              width={434}
              height={641}
            />
            <div className="relative w-full h-18 lg:h-20 bg-primary text-white text-center py-3">
              <div className="leading-tight">
                <h3 className="font-light">{v.txt1}</h3>
                <h3 className="hidden lg:inline-block text-nowrap">{v.txt2}</h3>
                <h5 className="inline-block lg:hidden">{v.txt2}</h5>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-5 mb-2 lg:mt-10 lg:mb-5 max-w-screen-md mx-auto">
        <img src="/images/about.png" alt="text" width={1212} height={185} />
      </div>
      <p className="text-[#007fff] text-center lg:text-2xl">
        유품정리&nbsp;·&nbsp;쓰레기집청소&nbsp;·&nbsp;빈집정리&nbsp;·
        특수청소&nbsp;·&nbsp;고독사청소
      </p>
    </div>
  );
};

export default About;
