import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import AOS from "aos";

const Banner = () => {
  return (
    <Swiper
      spaceBetween={30}
      centeredSlides={true}
      autoplay={{
        delay: 3500,
        disableOnInteraction: false,
      }}
      onSlideChangeTransitionStart={async () => {
        await document
          .querySelectorAll<HTMLDivElement>(".bannerTxt")
          .forEach((el) => {
            el.style.display = "none";
          });

        await document
          .querySelectorAll<HTMLDivElement>(".bannerTxt")
          .forEach((el) => {
            el.classList.remove("aos-init");
            el.classList.remove("aos-animate");
          });
      }}
      onSlideChangeTransitionEnd={async () => {
        await document
          .querySelectorAll<HTMLDivElement>(".bannerTxt")
          .forEach((el) => {
            el.style.display = "";
          });
        await AOS.init({ duration: 800 });
      }}
      loop={true}
      loopAdditionalSlides={1}
      mousewheel={false}
      keyboard={false}
      modules={[Autoplay]}
      className="mySwiper banner"
    >
      <SwiperSlide>
        <BannerComponent
          txt1={
            <h1
              data-aos="fade-left"
              className="bannerTxt text-white font-light lg:text-6xl xl:text-7xl italic text-shadow"
            >
              집치울때!
            </h1>
          }
          txt2={
            <h1
              data-aos="fade-left"
              data-aos-delay="50"
              className="bannerTxt text-white font-light lg:text-6xl xl:text-7xl italic text-shadow -my-3 lg:my-3 2xl:my-5"
            >
              생각나는!
            </h1>
          }
          txt3={
            <h1
              data-aos="fade-left"
              data-aos-delay="100"
              className="bannerTxt text-point font-light lg:text-6xl xl:text-7xl 2xl:text-[5rem] italic text-shadow"
            >
              바로&nbsp;그&nbsp;업체!
            </h1>
          }
          txt4={
            <h1
              data-aos="fade-left"
              data-aos-delay="150"
              className="bannerTxt text-white font-light lg:text-6xl xl:text-7xl italic text-shadow"
            >
              지금이 바로!
            </h1>
          }
          txt5={
            <h1
              data-aos="fade-left"
              data-aos-delay="200"
              className="bannerTxt text-point font-light lg:text-6xl xl:text-7xl 2xl:text-[5rem] italic text-shadow -my-3 lg:my-3 2xl:my-5"
            >
              집치울때!
            </h1>
          }
        />
      </SwiperSlide>
      <SwiperSlide>
        <BannerComponent
          txt1={
            <h1
              data-aos="fade-left"
              className="bannerTxt text-point font-light lg:text-6xl xl:text-7xl italic text-shadow"
            >
              수도권&nbsp;전지역!
            </h1>
          }
          txt2={
            <h1
              data-aos="fade-left"
              data-aos-delay="50"
              className="bannerTxt text-white font-light lg:text-6xl xl:text-7xl italic text-shadow -my-3 lg:my-3 2xl:my-5"
            >
              언제!
            </h1>
          }
          txt3={
            <h1
              data-aos="fade-left"
              data-aos-delay="100"
              className="bannerTxt text-white font-light lg:text-6xl xl:text-7xl 2xl:text-[5rem] italic text-shadow"
            >
              어디라도!
            </h1>
          }
          txt4={
            <h1
              data-aos="fade-left"
              data-aos-delay="150"
              className="bannerTxt text-point font-light lg:text-6xl xl:text-7xl italic text-shadow"
            >
              출장비&nbsp;없는!
            </h1>
          }
          txt5={
            <h1
              data-aos="fade-left"
              data-aos-delay="200"
              className="bannerTxt text-white font-light lg:text-6xl xl:text-7xl 2xl:text-[5rem] italic text-shadow -my-3 lg:my-3 2xl:my-5"
            >
              출장견적!
            </h1>
          }
        />
      </SwiperSlide>
      <SwiperSlide>
        <BannerComponent
          txt1={
            <h1
              data-aos="fade-left"
              className="bannerTxt text-white font-light lg:text-6xl xl:text-7xl italic text-shadow"
            >
              고객님의!
            </h1>
          }
          txt2={
            <h1
              data-aos="fade-left"
              data-aos-delay="50"
              className="bannerTxt text-white font-light lg:text-6xl xl:text-7xl italic text-shadow -my-3 lg:my-3 2xl:my-5"
            >
              <span className="text-point">든든한&nbsp;</span>이웃!
            </h1>
          }
          txt3={
            <h1
              data-aos="fade-left"
              data-aos-delay="100"
              className="bannerTxt text-white font-light lg:text-6xl xl:text-7xl 2xl:text-[5rem] italic text-shadow"
            >
              고민&nbsp;<span className="text-point">해결사!</span>
            </h1>
          }
          txt4={
            <h1
              data-aos="fade-left"
              data-aos-delay="150"
              className="bannerTxt text-point font-light lg:text-6xl xl:text-7xl italic text-shadow"
            >
              무료견적상담!
            </h1>
          }
          txt5={
            <h1
              data-aos="fade-left"
              data-aos-delay="200"
              className="bannerTxt text-white font-light lg:text-6xl xl:text-7xl 2xl:text-[5rem] italic text-shadow -my-3 lg:my-3 2xl:my-5"
            >
              받아보세요!
            </h1>
          }
        />
      </SwiperSlide>
      <SwiperSlide>
        <BannerComponent
          txt1={
            <h1
              data-aos="fade-left"
              className="bannerTxt text-white font-light lg:text-6xl xl:text-7xl italic text-shadow"
            >
              고객님을&nbsp;위한
            </h1>
          }
          txt2={
            <h1
              data-aos="fade-left"
              data-aos-delay="50"
              className="bannerTxt text-point font-light lg:text-6xl xl:text-7xl italic text-shadow -my-3 lg:my-3 2xl:my-5"
            >
              고객맞춤!
            </h1>
          }
          txt3={
            <h1
              data-aos="fade-left"
              data-aos-delay="100"
              className="bannerTxt text-white font-light lg:text-6xl xl:text-7xl 2xl:text-[5rem] italic text-shadow"
            >
              맞춤&nbsp;서비스!
            </h1>
          }
          txt4={
            <h1
              data-aos="fade-left"
              data-aos-delay="150"
              className="bannerTxt text-point font-light lg:text-6xl xl:text-7xl italic text-shadow"
            >
              싹~!&nbsp;다&nbsp;전부!
            </h1>
          }
          txt5={
            <h1
              data-aos="fade-left"
              data-aos-delay="200"
              className="bannerTxt text-white font-light lg:text-6xl xl:text-7xl 2xl:text-[5rem] italic text-shadow -my-3 lg:my-3 2xl:my-5"
            >
              치워드립니다.
            </h1>
          }
        />
      </SwiperSlide>
    </Swiper>
  );
};

export default Banner;

const BannerComponent = ({
  txt1,
  txt2,
  txt3,
  txt4,
  txt5,
}: {
  txt1: any;
  txt2: any;
  txt3: any;
  txt4: any;
  txt5: any;
}) => {
  return (
    <div className=" relative w-full min-h-[560px] h-[60vh] lg:h-[80vh] bg-gradient-to-t to-primary from-[#00d5ff] px-2">
      <div className="absolute bottom-0 left-0 w-full h-fit">
        <div className=" relative w-fit h-full mx-auto grid items-end">
          <img
            className="!relative w-auto h-64 lg:h-[55vh] object-cover object-center"
            src="/images/bannerBg.png"
            alt="banner"
            width={2226}
            height={687}
          />
        </div>
      </div>
      <div className="absolute bottom-0 left-0 w-full h-fit">
        <div>
          <img
            className="!relative w-auto 2xl:max-w-screen-xl sm:h-72 lg:h-[60vh] 2xl:h-auto 2xl:max-h-[60vh] mx-auto object-contain px-2 lg:pl-28 xl:pl-32 2xl:pl-44"
            src="/images/banner.png"
            alt="icon"
            width={1149}
            height={751}
          />
        </div>
      </div>
      <div className=" relative w-full h-full px-2 pt-7 lg:pt-10 2xl:pt-12 lg:flex justify-center lg:gap-56 xl:gap-64 2xl:gap-[450px] lg:text-right">
        <div className="super">
          {txt1}
          {txt2}
          {txt3}
        </div>
        <div className="super text-right mt-4 lg:mt-0">
          {txt4}
          {txt5}
        </div>
      </div>
    </div>
  );
};
