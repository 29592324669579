import { useEffect, useState } from "react";

import axios from "axios";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import About from "../components/home/About";
import Banner from "../components/home/Banner";
import Blog from "../components/home/Blog";
import Event from "../components/home/Event";
import Service from "../components/home/Service";
import Review from "../components/home/Review";
import Contact from "../components/home/Contact";
import Partners from "../components/home/Partners";

const Home = () => {
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    axios.get("/api/blog").then((res) => setData(res.data.data));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className=" relative w-full h-full">
      <Banner />
      <About />
      <Service />
      <Event />
      <Blog data={data} />
      <Review />
      <Contact />
      <Partners />
    </div>
  );
};

export default Home;
