const Quick = () => {
  return (
    <div className="fixed right-0 bottom-0 lg:right-[calc((100%-var(--container))/10)] lg:top-1/2 lg:-translate-y-32 lg:border-none w-full lg:w-fit h-fit z-40">
      <div
        onClick={() => (window.location.href = "/#contact")}
        className="absolute right-0 bottom-14 lg:right-auto lg:bottom-auto lg:relative w-fit h-fit ml-auto lg:ml-0 cursor-pointer"
      >
        <img
          className="!relative w-28 2xl:w-32 h-auto"
          src="/images/quick1.png"
          alt="quick"
          width={260}
          height={490}
        />
      </div>
      <div className="grid grid-cols-3 lg:block">
        <div
          onClick={() =>
            window.open("https://langlangshop.modoo.at/?link=2ikm2gye")
          }
          className=" relative w-full lg:w-fit h-fit cursor-pointer"
        >
          <img
            className="!relative w-full lg:w-28 2xl:w-32 h-auto"
            src="/images/quick2.png"
            alt="quick"
            width={260}
            height={95}
          />
        </div>
        <div
          onClick={() => window.open("https://open.kakao.com/o/s9BKJDZg")}
          className=" relative w-full lg:w-fit h-fit cursor-pointer"
        >
          <img
            className="!relative w-full lg:w-28 2xl:w-32 h-auto"
            src="/images/quick3.png"
            alt="quick"
            width={260}
            height={95}
          />
        </div>
        <div
          onClick={() =>
            window.open("https://talk.naver.com/ct/wcd01u?frm=pmd#nafullscreen")
          }
          className=" relative w-full lg:w-fit h-fit cursor-pointer"
        >
          <img
            className="!relative w-full lg:w-28 2xl:w-32 h-auto"
            src="/images/quick4.png"
            alt="quick"
            width={260}
            height={95}
          />
        </div>
      </div>
    </div>
  );
};

export default Quick;
