import { dateFormat } from "../../const/date";

const Blog = ({ data }: { data: any[] }) => {
  const blog = ParseData({ data });

  return (
    <div
      id="blog"
      className="relative w-full h-full py-14 lg:py-28 xl:pb-36 bg-[#FFFAD4] px-[calc((100%-var(--container))/2)]"
    >
      <div className="hidden lg:block absolute left-0 bottom-0 w-fit h-fit">
        <img
          className="!relative w-auto h-full max-h-96"
          src="/images/blog1.png"
          alt="icon"
          width={833}
          height={727}
        />
      </div>
      <div className=" absolute right-0 bottom-0 w-fit h-fit">
        <img
          className="!relative w-auto h-full max-h-52 lg:max-h-72"
          src="/images/blog2.png"
          alt="icon"
          width={827}
          height={570}
        />
      </div>
      <div id="title" className="relative w-full h-fit text-left mb-5 lg:mb-10">
        <div className="relative w-full h-fit flex justify-center items-end mb-3 lg:mb-5 flex-wrap xs:flex-nowrap gap-1.5">
          <div className="relative w-44 md:w-56 h-fit md:mx-0 mb-2 xs:mb-0">
            <img
              className="!relative w-full h-auto object-contain"
              alt="logo"
              src="/images/logo.png"
              width={430}
              height={104}
            />
          </div>
          <h2 className="leading-none font-light">BLOG&nbsp;작업사례</h2>
        </div>
        <p className=" lg:text-xl text-primary text-center lg:text-left">
          잠깐!&nbsp;문의하시기&nbsp;전에&nbsp;블로그&nbsp;작업사례를 한번
          확인해주세요! 고객님의 문의결정에 큰 도움이 됩니다.
        </p>
      </div>
      <div className="lg:grid grid-cols-4 lg:mt-3 xl:mt-14">
        <div className="super mb-5 lg:mb-0 lg:pt-5 text-center lg:text-left">
          <h3 className="text-white font-light text-shadow 2xl:text-3xl">
            집치울때!&nbsp;생각나는&nbsp;업체
          </h3>
          <h3 className="text-white font-light text-shadow 2xl:text-3xl mt-3 mb-2">
            수도권에서&nbsp;
            <span className="text-[#b8e9ff]">집&nbsp;치울땐?</span>
          </h3>
          <h3 className="text-white font-light text-shadow 2xl:text-3xl">
            <span className="text-[#b8e9ff]">집치울때</span>에&nbsp;연락주세요!
          </h3>
        </div>
        <div className="lg:col-span-3 grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-2 lg:px-10">
          {blog &&
            blog.map((v: any, i: number) => (
              <div
                key={i}
                onClick={() => window.open(v.link[0])}
                className="relative w-full h-full"
              >
                <div className="relative w-full h-full grid bg-white p-2 md:p-3 rounded-3xl text-left border hover:border-[var(--main-color)] cursor-pointer transition-all duration-300 overflow-hidden">
                  <p
                    className={`relative w-full whitespace-nowrap overflow-hidden overflow-ellipsis lg:text-base h-fit`}
                  >
                    {v.title[0]}
                  </p>
                  <small
                    className={`relative w-full whitespace-nowrap md:whitespace-normal overflow-hidden overflow-ellipsis h-fit md:h-9 lg:h-12 break-words md:line-clamp-2 text-xs opacity-60`}
                  >
                    {v.description[0]}
                  </small>
                  <div className="relative w-full md:pt-2 lg:flex justify-between items-end">
                    <small className="hidden xl:block opacity-40 lg:text-sm txt_line">
                      {dateFormat(v.pubDate[0])}
                    </small>
                    <small className="text-main lg:text-sm txt_line">
                      {v.category[0]}
                    </small>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

const parseString = require("xml2js").parseString;

const ParseData = ({ data }: { data: any[] }) => {
  const dataArr: {
    title: any;
    link: any;
    description: any;
    pubDate: any;
    category: any;
  }[] = [];

  data.length > 0 &&
    parseString(data, (e: any, data: any) => {
      // eslint-disable-next-line
      data.rss.channel[0].item.map((v: any, i: any) => {
        i < 12 &&
          dataArr.push({
            title: v.title,
            link: v.link,
            description: v.description,
            pubDate: v.pubDate,
            category: v.category,
          });
      });
    });

  return dataArr;
};

export default Blog;
