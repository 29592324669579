const Event = () => {
  return (
    <div className=" relative w-full h-full">
      <img
        className="hidden lg:block !relative w-full h-full object-cover"
        src="/images/event1.png"
        alt="background"
        width={2558}
        height={1273}
      />
      <img
        className="block lg:hidden !relative w-full h-full object-cover"
        src="/images/event2.png"
        alt="background"
        width={1050}
        height={1471}
      />
    </div>
  );
};

export default Event;
