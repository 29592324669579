const Review = () => {
  return (
    <div
      id="review"
      className="relative w-full h-full bg-[#FEE2A4] px-[calc((100%-var(--container))/2)] mx-auto pt-14 lg:pt-28"
    >
      <div
        id="title"
        className="relative w-full h-fit text-center mb-5 lg:mb-10"
      >
        <div className="relative w-full h-fit flex justify-center items-end mb-3 lg:mb-5 flex-wrap sm:flex-nowrap">
          <div className="relative w-44 md:w-56 h-fit sm:mx-0 mb-2 sm:mb-0">
            <img
              className="!relative w-full h-auto object-contain"
              alt="logo"
              src="/images/logo.png"
              width={430}
              height={104}
            />
          </div>
          <h2 className="leading-none font-light">
            <span className="hidden sm:inline-block">의</span>&nbsp;
            <span className="font-black text-primary">BEST</span>&nbsp;고객후기
          </h2>
        </div>
        <p className=" lg:text-xl">
          100% 완벽한 해결로 120% 고객만족을 달성하는 집치울때의 리얼한 후기!
        </p>
      </div>
      <div className="relative w-full h-full flex justify-center items-center flex-wrap-reverse lg:flex-nowrap gap-5">
        <div data-aos="fade-up" className=" relative w-full h-full">
          <img
            className="!relative w-full h-full object-contain"
            src="/images/review1.png"
            alt="review"
            width={1008}
            height={965}
          />
        </div>
        <div data-aos="fade-up" className=" relative w-full h-full">
          <img
            className="!relative w-full h-full object-contain"
            src="/images/review2.png"
            alt="review"
            width={1351}
            height={945}
          />
        </div>
      </div>
    </div>
  );
};

export default Review;
