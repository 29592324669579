import { useEffect, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

const Partners = () => {
  const partList = [
    {
      one: "/images/part1.png",
      two: "/images/part20.png",
      three: "/images/part9.png",
    },
    {
      one: "/images/part2.png",
      two: "/images/part19.png",
      three: "/images/part10.png",
    },
    {
      one: "/images/part3.png",
      two: "/images/part18.png",
      three: "/images/part11.png",
    },
    {
      one: "/images/part4.png",
      two: "/images/part17.svg",
      three: "/images/part8.svg",
    },
    {
      one: "/images/part5.png",
      two: "/images/part16.png",
      three: "/images/part12.png",
    },
    {
      one: "/images/part6.png",
      two: "/images/part15.png",
      three: "/images/part7.png",
    },
    {
      one: "/images/part7.png",
      two: "/images/part14.png",
      three: "/images/part20.png",
    },
    {
      one: "/images/part8.svg",
      two: "/images/part13.png",
      three: "/images/part3.png",
    },
    {
      one: "/images/part10.png",
      two: "/images/part12.png",
      three: "/images/part16.png",
    },
    {
      one: "/images/part11.png",
      two: "/images/part1.png",
      three: "/images/part5.png",
    },
  ];

  const [count, setCount] = useState(2);

  useEffect(() => {
    if (window.innerWidth >= 768) {
      setCount(8);
    } else {
      setCount(2);
    }

    window.addEventListener("resize", () => {
      if (window.innerWidth >= 768) {
        setCount(8);
      } else {
        setCount(2);
      }
    });
  }, []);
  return (
    <div
      id="partners"
      className="relative w-full h-full py-14 lg:py-28 xl:pb-36"
    >
      <div className="text-black relative w-full h-full px-[calc((100%-var(--container))/2)]  mx-auto ">
        <h1 className="font-light leading-normal 2xl:text-6xl 2xl:mb-3">
          집치울때와
        </h1>
        <h1 className="font-light leading-none 2xl:text-6xl">
          함께하는 파트너사
        </h1>
        <div className="relative w-full h-full"></div>
      </div>
      <div data-aos="fade-up" className=" relative w-full h-full py-10">
        <Swiper
          spaceBetween={30}
          centeredSlides={false}
          loop={true}
          slidesPerView={count}
          autoplay={{
            delay: 0,
            disableOnInteraction: false,
          }}
          speed={1500}
          loopAdditionalSlides={1}
          modules={[Autoplay]}
          className="mySwiper swiper-wrapper relative w-full h-full mb-5"
        >
          {partList.map((v, i) => (
            <SwiperSlide
              key={i}
              className=" relative w-full h-full overflow-hidden"
            >
              <div className="relative w-full h-full border px-3 py-6">
                <img
                  className="!relative w-auto max-w-20 h-8 lg:max-w-32 lg:h-14 mx-auto object-contain"
                  alt="logo"
                  src={v.one}
                  width={500}
                  height={500}
                />
              </div>
              <div className="relative w-full h-full border px-3 py-6 my-5">
                <img
                  className="!relative w-auto max-w-20 h-8 lg:max-w-32 lg:h-14 mx-auto object-contain"
                  alt="logo"
                  src={v.two}
                  width={500}
                  height={500}
                />
              </div>
              <div className="relative w-full h-full border px-3 py-6">
                <img
                  className="!relative w-auto max-w-20 h-8 lg:max-w-32 lg:h-14 mx-auto object-contain"
                  alt="logo"
                  src={v.three}
                  width={500}
                  height={500}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Partners;
