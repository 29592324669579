import { Outlet, useLocation } from "react-router-dom";
import MHeader from "./header/MHeader";
import MHeaderLine from "./header/MHeaderLine";
import Quick from "./quick/Quick";
import MFooter from "./footer/MFooter";
import QuickBar from "./quick/QuickBar";
import AHeader from "./header/AHeader";
import AMHeader from "./header/AMHeader";

const Layout = () => {
  const { pathname } = useLocation();

  console.log(pathname);

  if (pathname.includes("login")) {
    return (
      <div className="relative w-full h-full max-w-[100vw] overflow-x-hidden">
        <Outlet />
      </div>
    );
  }
  if (pathname.includes("admin")) {
    // if (!sessionStorage.getItem('email')) {
    //     window.location.href = '/admin/login';
    //     return <></>;
    // }
    return (
      <div className="relative w-full h-full max-w-[100vw] overflow-x-hidden">
        <AMHeader />
        <AHeader />
        <div className="relative w-full h-full py-32 lg:py-36 px-2 lg:pl-72 lg:pr-[calc((100%-var(--container))/2)] max-w-[var(--container-width)] ">
          <Outlet />
        </div>
      </div>
    );
  }

  return (
    <div className="relative w-full h-full max-w-[100vw] overflow-x-hidden">
      <MHeaderLine />
      <MHeader />
      <div className="relative w-full h-fit mt-28 lg:mt-28 xl:mt-40 3xl:mt-44 min-h-[50vh] lg:min-h-[60vh]">
        <Outlet />
      </div>
      <Quick />
      <QuickBar />
      <MFooter />
    </div>
  );
};

export default Layout;
