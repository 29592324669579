import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

const Contact = () => {
  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [service, setService] = useState<string>("");
  const [contents, setContents] = useState<string>("");

  const [loading, setLoading] = useState(false);

  const handlerPostContact = async () => {
    if (!name || !phone || !address) {
      Swal.fire({
        title: "이름, 연락처, 주소를 모두 입력해주세요.",
        icon: "error",
      });
      return false;
    }

    if (phone.length !== 11 || !phone.includes("010")) {
      alert("010을 포함한 연락처를 모두 입력해주세요.");
      return false;
    }

    if (!window.confirm("문의 접수를 위한 개인정보 수집에 동의하시나요?")) {
      return false;
    }

    setLoading(true);

    await axios
      .post("/api/contact", {
        name: name,
        phone: phone,
        address: address,
        service: service,
        contents: contents,
        path: sessionStorage.getItem("path"),
      })
      .then((res) => {
        if (res.data.result === "0000") {
          Swal.fire({
            title: "문의 완료!",
            text: "문의 등록이 완료되었습니다.",
            icon: "success",
          }).then(() => (window.location.href = "/transform"));
        }
      })
      .catch((e) => {
        setLoading(false);
        Swal.fire({
          title: e,
          icon: "error",
        }).then(() => (window.location.href = "/"));
      });
  };

  return (
    <div
      id="contact"
      className="relative w-full h-full px-[calc((100%-var(--container))/2)]  mx-auto py-14 lg:py-28 xl:py-36"
    >
      <div
        id="title"
        className="relative w-full h-fit text-center mb-5 lg:mb-10"
      >
        <div className="flex justify-center items-end mb-3 lg:mb-5 flex-wrap sm:flex-nowrap gap-2.5 lg:gap-0">
          <div className="relative w-48 md:w-56 h-fit mx-auto md:mx-0 mb-2 xs:mb-0">
            <img
              className="!relative w-full h-auto object-contain"
              alt="logo"
              src="/images/logo.png"
              width={430}
              height={104}
            />
          </div>
          <h2 className="leading-none font-light">상세견적&nbsp;요청하기</h2>
        </div>
        <p className=" lg:text-xl">
          전화상담,&nbsp;견적문의&nbsp;모두&nbsp;무료입니다! 부담없이 편하게
          견적상담부터&nbsp;받아보세요!
        </p>
      </div>
      <div
        data-aos="fade-up"
        className="relative w-full h-fit border-2 border-primary rounded-sm"
      >
        <div className="relative w-full h-full grid grid-cols-1 lg:grid-cols-4">
          <div className="lg:col-span-3 p-5 relative w-full h-full grid grid-cols-1 lg:grid-cols-2 items-center gap-5">
            <div className=" relative w-full h-full grid grid-cols-5 items-center">
              <label htmlFor="name" className="lg:text-xl">
                이름&nbsp;<span className="text-[var(--red-color)]">*</span>
              </label>
              <input
                className="col-span-4"
                type="text"
                value={name}
                id="name"
                placeholder="이름을 입력해주세요."
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className=" relative w-full h-full grid grid-cols-5 items-center">
              <label htmlFor="phone" className="lg:text-xl">
                연락처&nbsp;<span className="text-[var(--red-color)]">*</span>
              </label>
              <input
                className="col-span-4"
                type="text"
                id="phone"
                value={phone}
                maxLength={11}
                placeholder="연락처를 입력해주세요."
                onChange={(e) =>
                  setPhone(
                    e.target.value
                      .replace(/[^0-9.]/g, "")
                      .replace(/(\..*)\./g, "$1")
                  )
                }
              />
            </div>
            <div className="lg:col-span-2 relative w-full h-full grid grid-cols-5 lg:grid-cols-10 items-center">
              <label htmlFor="address" className="lg:text-xl">
                주소&nbsp;<span className="text-[var(--red-color)]">*</span>
              </label>
              <input
                className="col-span-4 lg:col-span-9"
                type="text"
                id="address"
                value={address}
                placeholder="주소를 입력해주세요."
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div className=" relative w-full h-full grid grid-cols-5 items-center">
              <label htmlFor="service" className="lg:text-xl">
                서비스
              </label>
              <select
                className="col-span-4 bg-point text-primary"
                name="service"
                id="service"
                onChange={(e) => setService(e.target.value)}
              >
                <option value="">원하시는 서비스를 선택해주세요</option>
                <option value="유품정리">유품정리</option>
                <option value="빈집정리">빈집정리</option>
                <option value="폐업정리">폐업정리</option>
                <option value="쓰레기집">쓰레기집</option>
                <option value="고독사현장">고독사현장</option>
                <option value="특수청소">특수청소</option>
                <option value="폐기물처리">폐기물처리</option>
                <option value="이사정리">이사정리</option>
              </select>
            </div>
            <div className=" relative w-full h-full grid grid-cols-3 items-center">
              <p className="col-span-2 text-xs lg:text-sm">
                문자&nbsp;확인&nbsp;후&nbsp;바로!&nbsp;회신드리겠습니다.
                <br />( 평균확인 시간 : 1시간 이내 )
              </p>
              <button
                type="button"
                disabled={loading}
                onClick={handlerPostContact}
                className=" relative w-full h-full bg-point text-primary rounded-full border border-primary pt-1"
              >
                <h3>전송하기</h3>
              </button>
            </div>
            <div className=" relative w-full h-full">
              <label htmlFor="contents" className="lg:text-xl">
                문의 내용
              </label>
            </div>
            <div className=" relative w-full h-full lg:col-span-2 lg:row-span-2 -mt-5">
              <textarea
                value={contents}
                name="contents"
                id="contents"
                onChange={(e) => setContents(e.target.value)}
              ></textarea>
            </div>
          </div>
          <div className=" relative w-full h-full overflow-visible"></div>
          <div className=" relative lg:absolute -right-5 lg:-right-14 -bottom-1 w-fit h-fit mx-auto lg:mx-0">
            <img
              className="!relative w-auto h-full max-h-80 object-container"
              src="/images/contact.png"
              alt="icon"
              width={719}
              height={655}
            />
          </div>
        </div>
      </div>
      <div
        data-aos="fade-up"
        className="relative w-full h-fit mt-5 px-1 py-3 lg:p-3 border-2 border-primary bg-gradient-to-r to-[#00d5ff] from-primary text-white rounded-sm"
      >
        <p className="lg:text-xl">
          이것저것&nbsp;요모조모&nbsp;따져볼&nbsp;거&nbsp;다&nbsp;따져보고&nbsp;싶다면?
          지금&nbsp;바로&nbsp;전화상담부터&nbsp;받아보세요!
        </p>
        <div className="flex justify-end items-center lg:gap-1 mt-3">
          <img
            className=" w-8 lg:w-12 h-auto"
            src="/images/contactIcon.png"
            alt="icon"
            width={50}
            height={50}
          />
          <h2 className="manru font-black leading-none sm:text-4xl lg:text-6xl">
            010.9060.7799
          </h2>
        </div>
      </div>
    </div>
  );
};

export default Contact;
